import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path:'',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path:'admin-dashboard',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path: "candidate",
        loadChildren: () => import("../../admin/pages/candidate/candidate.module").then((m) => m.CandidateModule),
    },
    {
        path: "formation",
        loadChildren: () => import("../../admin/pages/formation/formation.module").then((m) => m.FormationModule),
    },
    {
        path: "portofolio",
        loadChildren: () => import("../../admin/pages/portofolio/portofolio.module").then((m) => m.PortofolioModule),
    },
    {
        path: "interview",
        loadChildren: () => import("../../admin/pages/interview/interview.module").then((m) => m.InterviewModule),
    },
    {
        path: "salary",
        loadChildren: () => import("../../admin/pages/salary/salary.module").then((m) => m.SalaryModule),
    },
    {
        path: "user",
        loadChildren: () => import("../../admin/pages/user/user.module").then((m) => m.UserModule),
    },
    {
        path: "payment",
        loadChildren: () => import("../../admin/pages/payment/payment.module").then((m) => m.PaymentModule),
    },
    {
        path: "**",
        loadChildren: () => import("../../admin/pages/error/error.module").then((m) => m.ErrorModule),
    },
];
