import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import { AuthenticationComponent } from "./admin/pages/authentication/authentication.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import {ResetPasswordComponent} from './admin/pages/authentication/reset-password/reset-password.component';
import {ForgetPasswordComponent} from './admin/pages/authentication/forget-password/forget-password.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./admin/pages/authentication/authentication.module').then((module) => module.AuthenticationModule)
      },   
    ]
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content,
   
  },

];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
